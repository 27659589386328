import type { ReactNode } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import clsx from 'clsx';
import { SubscriptionPlanInterval, SubscriptionPlanStripe } from 'generated/graphql';
import giftPlusImage from 'public/images/christmas2024/plus.png';
import giftImage from 'public/images/christmas2024/vinyl.avif';
import useIntl from 'src/hooks/use-intl';
import useSession from 'src/hooks/use-session';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';
import { isInAbTest } from 'src/utilities/ab-tests';
import { CurrencyCode } from 'src/utilities/currency-code-helpers';
import { getPrice } from 'src/utilities/price-helpers';

type SubscriptionPlanProps = {
  /**
   * The subscription plan to display.
   */
  subscriptionPlan: SubscriptionPlanStripe;
  /**
   * All the subscription plans.
   */
  allSubscriptionPlans?: SubscriptionPlanStripe[];
  /**
   * Whether the radio button group is currently in focus
   * @default false
   */
  focus?: boolean;
  /**
   * Whether the plan is currently selected by the user.
   * @default false
   */
  checked?: boolean;
  /**
   * Whether the plan is disabled (e.g. when switching plans is not allowed)
   * @default false
   */
  disabled?: boolean;
  /**
   * The currency code to use for displaying the price.
   */
  currencyCode?: CurrencyCode;
};

// Translation keys for the different subscription plan intervals
const subscriptionPlanTranslationKeys: Record<
  SubscriptionPlanInterval,
  {
    title: TranslationKeyCommon;
    period: TranslationKeyCommon;
    renewal: TranslationKeyCommon;
  }
> = {
  [SubscriptionPlanInterval.Days_7]: {
    title: 'subscription_plan__days_7_title',
    period: 'subscription_plan__days_7_period',
    renewal: 'subscription_plan__days_7_renewal',
  },
  [SubscriptionPlanInterval.Monthly]: {
    title: 'subscription_plan__monthly_title',
    period: 'subscription_plan__monthly_per_period',
    renewal: 'subscription_plan__monthly_renewal',
  },
  [SubscriptionPlanInterval.Yearly]: {
    title: 'subscription_plan__yearly_title',
    period: 'subscription_plan__yearly_per_period',
    renewal: 'subscription_plan__yearly_renewal',
  },
};

// Translation keys and highlight status for the badge based on the subscription plan interval
function getBadgeProps(
  subscriptionPlanInterval: SubscriptionPlanInterval,
  ticketInterval?: SubscriptionPlanInterval,
  highlight?: boolean,
): { translationKey: TranslationKeyCommon; highlight: boolean } | undefined {
  const isCurrentPlan = ticketInterval === subscriptionPlanInterval;
  const isYearlyPlan = subscriptionPlanInterval === SubscriptionPlanInterval.Yearly;
  // If the plan is the current plan, show the "active" badge and highlight it if it's already selected
  if (isCurrentPlan) return { translationKey: 'subscription_plan__badge_active', highlight: highlight || false };
  // If the plan is a yearly plan, show the "yearly" badge and always highlight it
  if (isYearlyPlan) return { translationKey: 'subscription_plan__badge_yearly', highlight: true };
}

/**
 * A helper function to calculate the savings of a yearly subscription plan,
 * compared to monthly plan, in the given currency.
 */
export function calculateYearlySavings(
  yearlyPlan: SubscriptionPlanStripe,
  monthlyPlan: SubscriptionPlanStripe,
  currencyCode: CurrencyCode,
): {
  amount: number;
  percentage: number;
} {
  // assume 12 monthly payments per year (alternative would be 4-weekly payments)
  const monthlyPaymentsPerYear = 12;
  const yearlyPriceValue = getPrice(yearlyPlan, currencyCode).amount;
  const monthlyPriceValue = getPrice(monthlyPlan, currencyCode).amount;
  const monthlyCostPerYear = (monthlyPriceValue || 0) * monthlyPaymentsPerYear;
  // Calculate the savings by subtracting the yearly price from the monthly cost per year
  // keep in mind that in some cases, with certain promotions, there might be no savings, e.g the difference is negative
  const savings = Math.max(0, monthlyCostPerYear - yearlyPriceValue);
  return {
    amount: savings,
    percentage: Math.round((savings / monthlyCostPerYear) * 100),
  };
}

/**
 * Badge on top of the plan card to highlight savings and such.
 */
function Badge({ highlight, children }: { highlight?: boolean; children: ReactNode }) {
  return (
    <div
      data-testid="subscription-plan-badge"
      className={clsx(
        'rounded px-2 py-1 typo-caption-2 lg:absolute lg:left-4 lg:top-0 lg:-translate-y-1/2',
        highlight
          ? 'border border-brand-dg bg-brand-stage uppercase text-brand-dg'
          : 'bg-buttonBlueC8 uppercase text-white',
      )}
      suppressHydrationWarning
    >
      {children}
    </div>
  );
}

/**
 * Special badge for gifts. For now we hardcode the text and image for the
 * Christmas 2024 campaign. In the future, we may make it more generic.
 */
function BadgeGift() {
  const t = useTranslate();
  return (
    <div
      data-testid="subscription-plan-badge"
      className="relative flex gap-1 rounded-md border border-brand-dg bg-brand-dg px-3 py-1 text-brand-stage typo-caption-2 lg:absolute lg:left-4 lg:top-0 lg:-translate-y-1/2"
    >
      <div
        className="absolute inset-x-0 bottom-full h-[42px] min-w-[64px] bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url('${giftImage.src}')` }}
      ></div>
      <img src={giftPlusImage.src} width={14} height={14} alt="" />
      <span>{t('campaigns__gift_badge')}</span>
    </div>
  );
}

/**
 * A component to display a subscription plan.
 * Typically used in a list of subscription plans (e.g. when choosing a subscription).
 */
export default function SubscriptionPlan({
  subscriptionPlan,
  focus = false,
  checked = false,
  disabled = false,
  currencyCode,
  allSubscriptionPlans,
}: SubscriptionPlanProps) {
  const t = useTranslate();
  const { currencyFormat } = useIntl();
  const session = useSession();
  const { interval, trialPeriodDays, gift } = subscriptionPlan;
  const price = currencyCode && getPrice(subscriptionPlan, currencyCode);
  const translationKeys = subscriptionPlanTranslationKeys[interval];
  const badgeProps = getBadgeProps(interval, session?.data?.currentUser?.ticket?.interval, checked);
  const hasGift = gift?.id !== undefined && !disabled;

  // define the default values for the badge, renewal and trial period texts
  let badgeText = badgeProps && t(badgeProps.translationKey);
  let renewalText: string | undefined = t(translationKeys.renewal);
  let trialPeriodText: string | undefined =
    trialPeriodDays > 0 ? t('subscription_plan__trial_duration', [trialPeriodDays]) : undefined;

  // @todo [2024-11-21] remove the following block when the experiment is over
  // also remove the `suppressHydrationWarning` in jsx, as they are not needed anymore

  // First, check if we are in an experiment
  // if we are, we need to render the alternative UI texts
  const variant = isInAbTest('xp2024-10-t2');
  // use the translation function for the a-b test
  const tAbTest = useTranslate('abtesting');

  if (variant === 'v1') {
    if (subscriptionPlan.interval === SubscriptionPlanInterval.Monthly) {
      trialPeriodText = tAbTest('xp_2024_09_t2__monthly_trial');
    } else if (subscriptionPlan.interval === SubscriptionPlanInterval.Yearly) {
      // note: in the test variation, the trial text slot is used for the renewal text
      trialPeriodText = tAbTest('xp_2024_09_t2__yearly_trial');
      // keep in mind that because of the order the texts are displayed,
      // the renewal text in test is referring to the trial period and vice versa
      renewalText = trialPeriodDays > 0 ? tAbTest('xp_2024_09_t2__yearly_renewal') : undefined;

      // calculate the yearly badge text based on the difference between the current plan and the monthly plan
      const monthlyPlan = allSubscriptionPlans?.find((plan) => plan.interval === SubscriptionPlanInterval.Monthly);
      // get the price of the monthly plan based on the current currency
      if (price && monthlyPlan && currencyCode) {
        // calculate the difference between the yearly and monthly plan
        const savingsDifference = calculateYearlySavings(subscriptionPlan, monthlyPlan, currencyCode);
        // if the yearly plan is cheaper than the monthly plan, display the savings in PERCENTAGE
        badgeText =
          savingsDifference.amount > 0
            ? tAbTest('xp_2024_09_t2__yearly_badge_v1', [`${savingsDifference.percentage}%`])
            : undefined;
      }
    }
  } else if (variant === 'v2') {
    if (subscriptionPlan.interval === SubscriptionPlanInterval.Monthly) {
      trialPeriodText = tAbTest('xp_2024_09_t2__monthly_trial');
    } else if (subscriptionPlan.interval === SubscriptionPlanInterval.Yearly) {
      // calculate the yearly badge text based on the difference between the current plan and the monthly plan
      const monthlyPlan = allSubscriptionPlans?.find((plan) => plan.interval === SubscriptionPlanInterval.Monthly);
      // get the price of the monthly plan based on the current currency
      if (price && monthlyPlan && currencyCode) {
        // calculate the difference between the yearly and monthly plan
        const savingsDifference = calculateYearlySavings(subscriptionPlan, monthlyPlan, currencyCode);
        // round the savings to the nearest 100, e.g. 1490 -> 1500
        // except for JPY, where we don't need to round
        const roundedSavings =
          currencyCode === 'JPY' ? savingsDifference.amount : Math.round(savingsDifference.amount / 100) * 100;
        // if the yearly plan is cheaper than the monthly plan, display the savings in CURRENCY
        badgeText =
          savingsDifference.amount > 0
            ? tAbTest('xp_2024_09_t2__yearly_badge_v2', [currencyFormat(roundedSavings, price.currency)])
            : undefined;
      }
      // note: in the test variation, the trial text slot is used for the renewal text
      trialPeriodText = tAbTest('xp_2024_09_t2__yearly_trial');
      // keep in mind that because of the order the texts are displayed,
      // the renewal text in test is referring to the trial period
      renewalText = trialPeriodDays > 0 ? tAbTest('xp_2024_09_t2__yearly_renewal') : undefined;
    }
  }

  return (
    <div
      className={clsx(
        'relative flex h-full select-none flex-row gap-2 rounded border p-3 lg:p-4',
        // use a transparent background when the plan is disabled, a form blue background otherwise
        disabled ? 'bg-transparent' : 'cursor-pointer bg-formBlueC5',
        // use a 15% white border when the plan is disabled, a brand yellow border when checked, and a 35% white border otherwise
        disabled ? 'border-white/15' : checked ? 'border-brandYellowC1' : 'border-white/35',
        focus && 'outline outline-2 outline-offset-2 outline-focusOutline',
      )}
    >
      {/* Checkbox */}
      {!disabled && (
        <div className="size-[18px] shrink-0 lg:absolute lg:right-4 lg:top-4">
          {checked ? (
            <CheckmarkIcon className="size-full rounded-full bg-brandYellowC1 text-darkBlueC7" />
          ) : (
            <div className="size-full rounded-full border border-white/35" />
          )}
        </div>
      )}

      {/* Plan details */}
      <div className="flex flex-1 flex-col gap-2">
        <div className="flex flex-col gap-0.5 lg:pr-6">
          <div className="flex flex-row justify-between gap-2 lg:min-h-11">
            {/* Title */}
            <div className="dg-text-medium-9 text-white">{t(translationKeys.title)}</div>
            {/* Optional badge:
                1. Show a special gift badge when the plan has a gift.
                2. If the plan has no gift, show the default badge for the active
                   plan or the amount of savings.
                3. Don’t show gift badge for disabled plans (this also avoids
                   overwriting the active plan badge).
             */}
            {badgeText && !hasGift && <Badge highlight={badgeProps?.highlight}>{badgeText}</Badge>}
            {hasGift && <BadgeGift />}
          </div>
          {/* Optional strikethrough price */}
          {price && price.amountUndiscounted > price.amount && (
            <s className="dg-text-regular-6 inline-block lining-nums text-white/55 line-through">
              {currencyFormat(price.amountUndiscounted, price.currency)}
            </s>
          )}
          {/* Price */}
          {price && (
            <div className="dg-text-regular-6 lining-nums text-white">
              {t(translationKeys.period, [currencyFormat(price.amount, price.currency)])}
            </div>
          )}
        </div>
        {/* Plan detail list */}
        <ul className="dg-text-regular-6 text-white/70">
          <li suppressHydrationWarning>{renewalText}</li>
          <li suppressHydrationWarning className="lg:whitespace-nowrap">
            {trialPeriodText}
          </li>
        </ul>
      </div>
    </div>
  );
}
